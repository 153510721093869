import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import DashBoard from "./Pages/DashBoard/DashBoard";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import Cms from "./Pages/CMS/CMS";
import Company from "./Pages/Company/Company";
import ClientLogo from "./Pages/ClientLogo/ClientLogo";
import MasterMenu from "./Pages/MasterMenu/MasterMenu";
import SubMenu from "./Pages/SubMenu/SubMenu";
import Doctor from "./Pages/Doctor/Doctor";
import SliderImage from "./Pages/SliderImage/SliderImage";
import Event from "./Pages/Event/Event";
import Gallery from "./Pages/Gallery/Gallery";
import Inquiry from "./Pages/Inquiry/Inquiry";
import VideoMaster from "./Pages/VideoMaster/VideoMaster";
import Videos from "./Pages/Videos/Videos";
import HomePopUp from "./Pages/HomePopUp/HomePopUp";
import UserMaster from "./Pages/UserMaster/UserMaster";
import Protected from "./Features/Protected";
import Logout from "./Pages/Logout/Logout";
import Product from "./Pages/Product/Product";
import ProductCategory from "./Pages/ProductCategory/ProductCategory";
import Contact from "./Pages/Contact/Contact";
import Blog from "./Pages/Blog/Blog";
import Carousel from "./Pages/Carousel/Carousel";

const routeConfig = [
  { path: "/Dashboard", component: DashBoard },
  { path: "*", component: DashBoard },
  { path: "/CMS", component: Cms },
  { path: "/Company", component: Company },
  { path: "/ClientLogo", component: ClientLogo },
  { path: "/Contact", component: Contact },
  { path: "/MasterMenu", component: MasterMenu },
  { path: "/SubMenu", component: SubMenu },
  { path: "/Doctor", component: Doctor },
  { path: "/UserMaster", component: UserMaster },
  { path: "/SliderImage", component: SliderImage },
  { path: "/Event", component: Event },
  { path: "/Gallery", component: Gallery },
  { path: "/Inquiry", component: Inquiry },
  { path: "/VideoMaster", component: VideoMaster },
  { path: "/Videos", component: Videos },
  { path: "/HomePopUp", component: HomePopUp },
  { path: "/Product", component: Product },
  { path: "/ProductCategory", component: ProductCategory },
  { path: "/Blog", component: Blog },
  { path: "/Carousel", component: Carousel },
];

export default function RoutesComponent() {
  return (
    <Routes>
      {routeConfig.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<Protected Component={route.component} />}
        />
      ))}
      <Route path="/logout" element={<Protected Component={Logout} />} />
      <Route path="*" element={<Protected Component={PageNotFound} />} />
    </Routes>
  );
}

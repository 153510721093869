import React, { useState, useEffect,useRef } from "react";
import { Card, Button, Image } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../Utils/api";
import Swal from "sweetalert2";
import AgGridComponent from "../../Components/Common/AgGridComponent";
import GlobalModal from "../../Components/Common/GlobalModal";
import SliderImageComponent from "./SliderImageComponent";
import UserSetting from "../../UserSettings.json";
import Loader from "../../Components/Common/Loader ";
import Carousel from "react-bootstrap/Carousel";
import SliderErrorImage from "../../ImageForSidebar/SliderErrorImage.jpg"
import { Edit ,Delete } from "../../Components/Common/EditDelete";


export default function SliderImage() {
  const [SliderImageData, setSliderImageData] = useState([]);
  const [editSliderImageData, setEditSliderImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSliderImageModal, setShowSliderImageModal] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    getSliderImage();
  }, []);

  // Fetch all Areas
  const getSliderImage = () => {
    ;
    setIsLoading(true);
    api
      .get("Slider/AllSliders")
      .then((result) => {
        setSliderImageData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSingleCompony = (id) => {
    ;
    api
      .get(`Slider/SingleSlider?SliderID=${id}`)
      .then((result) => {
        const SliderImageData = result.data.data;
        setEditSliderImageData(SliderImageData);
        setShowSliderImageModal(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Delete Area
  const DeleteSliderImage = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to Delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .patch(`Slider/DeleteSlider?SliderID=${id}`)
          .then((result) => {
            if (result.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Your record has been deleted.",
                showConfirmButton: false,
                timer: 1500,
              });
              getSliderImage();
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire("Error", "An error occurred.", "error");
          });
      }
    });
  };


  
  // const columnDefs = [
  //   {
  //     headerName: "DisplayPriority",
  //     field: "displayPriority",
  //     headerClass: "widthTableColum",
  //     sortable: true,
  //     filter: true,
  //     wrapHeaderText: true,
  //     autoHeaderHeight: true,
  //     wrapText: true,
  //     autoHeight: true,
  //     flex: 1,
  //     minWidth: 50,
  //   },
  //   {
  //     headerName: "Client Logo",
  //     flex: 1,
  //     minWidth: 150,
  //     field: "SliderImagePath",
  //     autoHeight: true,
  //     cellStyle: { textAlign: "center" },
  //     headerClass: "center-text widthTableColum ",
  //     cellRenderer: ({ value }) => (
  //       <div className="p-2.5">
  //         <Image src={UserSetting.imgURL + value}></Image>
  //       </div>
  //     ),
  //   },
  //   {
  //     headerName: "Edit",
  //     width: 70,
  //     field: "SliderImageID",
  //     cellStyle: { textAlign: "center" },
  //     headerClass: "center-text widthTableColum",
  //     cellRenderer: ({ value }) => (
  // <ion-icon
  //   name="create-outline"
  //   class="iconsFonts editIcon"
  //   onClick={() => getSingleCompony(value)}
  // ></ion-icon>
  //     ),
  //     pinned: "right",
  //   },
  //   {
  //     headerName: "Delete",
  //     width: 90,
  //     field: "SliderImageID",
  //     cellStyle: { textAlign: "center" },
  //     headerClass: "center-text widthTableColum",
  //     cellRenderer: ({ value }) => (
  // <ion-icon
  //   name="trash-outline"
  //   class="iconsFonts deleteIcon"
  //   onClick={() => DeleteSliderImage(value)}
  // ></ion-icon>
  //     ),
  //     pinned: "right",
  //   },
  // ];
  const HandlModelClose = () => {
    setShowSliderImageModal(false);
    setEditSliderImageData(null);
  };
  return (
    <>
      {isLoading && <Loader />}
      <ToastContainer />
      <Card.Header className="d-flex justify-content-between">
        <span>Client Logo</span>
        <Button
          className="btn-tapti"
          onClick={() => setShowSliderImageModal(true)}
        >
          Add SliderImage
        </Button>{" "}
      </Card.Header>
      <Card.Body className="withBorder">
        {/* <AgGridComponent
          rowData={SliderImageData}
          columnDefs={columnDefs}
          paginationPageSize={4}
        /> */}
        <Carousel data-bs-theme="dark">
          {SliderImageData.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={image.imagePath ? UserSetting.imgURL + image.imagePath : SliderErrorImage}
                alt={`Slide ${index}`}
              />
              <Carousel.Caption>
                <h5>{image.title}</h5>
                <div>{image.displayPriority}</div>
                <div dangerouslySetInnerHTML={{ __html: image.discription }} />
                <div className="d-flex justify-content-between">
                  {/* <ion-icon
                    name="create-outline"
                    class="iconsFonts editIcon"
                    onClick={() => getSingleCompony(image.sliderID)}
                  ></ion-icon> */}
                  <button  onClick={() => getSingleCompony(image.sliderID)}><Edit/></button>
                  <button  onClick={() => DeleteSliderImage(image.sliderID)}><Delete/></button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </Card.Body>

      <GlobalModal
        title="New SliderImage"
        bodyComponent={
          <SliderImageComponent
            onClose={HandlModelClose}
            SliderImageID={getSliderImage}
            editedSliderImageData={editSliderImageData}
            clearData={showSliderImageModal}
          />
        }
        isOpen={showSliderImageModal}
        onClose={HandlModelClose}
      />
    </>
  );
}
